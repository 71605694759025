import { storeService } from "./StoreService";

class MapService {
	customerPosition = {
		lat: undefined,
		lng: undefined,
	};

	storePosition = {
		lat: undefined,
		lng: undefined
	};
	allowedPingLocationCorners;

	polygon;
	mapRef;

	constructor(){
		//this._initStoreData();
	}

	isCustomerInLocation(){
		return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(position => {
				console.log(position);
				this.customerPosition.lat = position.coords.latitude;
				this.customerPosition.lng = position.coords.longitude;

				resolve(this._isInLocation());
			}, err => {
				console.error(err);
				alert("We couldn't get your location\n   • Make sure that location (GPS) is on.\n   • Allow curbsidebell.com to get your location.")
				reject();
			});
		});
	}

	initMapOnElement(element){
		this.mapRef = new window.google.maps.Map(element, {
			center: this.customerPosition,
			zoom: 14,
			mapTypeId: window.google.maps.MapTypeId.ROADMAP,
		});

		 new window.google.maps.InfoWindow({
			map: this.mapRef,
			content: "You are Here.",
			position: this.customerPosition,
		});
		new window.google.maps.InfoWindow({
			map: this.mapRef,
			content: "You should be here",
			position: this.storePosition,
		});

		this.polygon.setMap(this.mapRef);
	}

	_isInLocation() {
		return window.google.maps.geometry.poly.containsLocation(new window.google.maps.LatLng(this.customerPosition.lat, this.customerPosition.lng), this.polygon);
	}

	_initStoreData(store){
		storeService.getStoreAsync()
			.then(store => {
				this.storePosition.lat = store.storeConfig.storeLatitudes;
				this.storePosition.lng = store.storeConfig.storeLongitudes;

				this.allowedPingLocationCorners = store.allowedPingLocationCorners.map(e => {
					return {lat :e.latitudes, lng: e.longitudes}
				});
				this.allowedPingLocationCorners.push(this.allowedPingLocationCorners[0]);

				this._initPolygon();
			});
	}

	_initPolygon(){
		this.polygon = new window.google.maps.Polygon({
			paths: this.allowedPingLocationCorners,
			strokeColor: '#023bca',
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillColor: '#0748eb',
			fillOpacity: 0.35
		});
	}

}
export let mapService = new MapService();
